import MedicalSpeciality from '@/statics/medicalSpeciality';
import Range from '@/statics/range';
import Trolley from '@/statics/trolley';
import { Feature, GIProcedureArea, LowerGILength, RespiratoryType } from '@/statics/scopeFilter';
import { LocaleCode } from '@/statics/locale';
import { ProcedureType, ProcedureTypeSelectOptions } from '@/statics/procedureType';

export default {
  meta: {
    /* same as meta infos in index_es.html */
    title: 'Sistema de la torre de endoscopia — Configuración en 5 pasos',
    description:
      'Seleccione el enfoque de sus procedimientos, optimice en función de sus necesidades y obtenga su configuración de torre de endoscopia personalizada por correo electrónico.'
  },
  locale: {
    [LocaleCode.DE]: 'Alemán',
    [LocaleCode.EN]: 'Inglés (US)',
    [LocaleCode.EN_GB]: 'Inglés (UK)',
    [LocaleCode.ES]: 'Español',
    [LocaleCode.IT]: 'Italiano',
    [LocaleCode.NL]: 'Holandés',
    [LocaleCode.RU]: 'Ruso'
  },
  step: 'Paso',
  quantity: 'Cantidad',
  shortPcsCounted: '1 ud. | {n} uds.',
  progressBarTitles: {
    gettingStarted: '@:gettingStarted.title',
    procedures: '@:procedures.title',
    optimize: '@:optimize.title',
    accessories: '@:accessories.title',
    request: '@:request.title'
  },
  loadingConfiguration: 'Cargando la configuración...',
  gettingStarted: {
    title: 'Primeros pasos',
    startScreenHeadline: 'Configure su nueva torre de endoscopia en solo unos pasos.',
    medicalSpecialityHeadline: 'Elija su especialidad médica',
    medicalSpeciality: {
      [MedicalSpeciality.GASTROENTEROLOGY]: 'Gastroenterología',
      [MedicalSpeciality.PULMONOLOGY]: 'Neumología'
    },
    trolleyProcedureTypesHeadline: 'Elija el enfoque de sus procedimientos',
    procedureTypeSelectOptions: {
      [ProcedureTypeSelectOptions.ADVANCED]: 'Procedimientos y opciones avanzados',
      [ProcedureTypeSelectOptions.GENERAL]: 'Procedimientos generales'
    }
  },
  procedures: {
    title: 'Procedimientos',
    headline: 'Elija sus procedimientos y el enfoque de su configuración preferida.',
    procedureRequest: '¿Para qué procedimientos prevé invertir en nuevo equipamiento?',
    procedureTypes: {
      [ProcedureType.DIAGNOSTIC]: 'Procedimientos generales',
      [ProcedureType.THERAPEUTIC]: 'Procedimientos y opciones avanzados'
    },
    errorNoProcedureSelected: 'Seleccione primero uno de los procedimientos siguientes.',
    selectAll: 'Seleccionar todo',
    resetSelection: 'Restablecer la selección',
    hasRequirementsInfo: 'Seleccione primero al menos uno de los procedimientos:',
    isRequirementInfo: 'Descarte primero estos procedimientos seleccionados:',
    rangeRequest: 'Elija el enfoque de su configuración preferida',
    range: {
      [Range.BASIC]: 'Mejor precio',
      [Range.MID_RANGE]: 'Precio e innovación',
      [Range.INNOVATION]: 'Mejor rendimiento'
    }
  },
  optimize: {
    title: 'Optimizar',
    trolleys: {
      [Trolley.MAIN]: 'Torre de endoscopia',
      [Trolley.GHOST]: 'Elementos adicionales',
      [Trolley.ESG]: 'Generadores de alta frecuencia'
    },
    trolleyTitles: {
      [Trolley.MAIN]: 'Su torre de endoscopia',
      [Trolley.GHOST]: `@:optimize.trolleys.${Trolley.GHOST}`,
      [Trolley.ESG]: `@:optimize.trolleys.${Trolley.ESG}`
    },
    empty: 'Vacío',
    addComponent: 'Añadir componente',
    scopeGuide: 'ScopeGuide',
    zones: {
      M0: 'Monitores',
      M1: 'Inteligencia artificial',
      M2: 'Procesadores de vídeo',
      M3: 'Procesador ultrasónico',
      M4: 'Bombas',
      M5: 'Insuflador de CO2',
      M6: 'Generadores de alta frecuencia',
      M7: 'Endoscopios'
    },
    selectedCounted: '{count} seleccionados',
    viewESG: 'Generadores de alta frecuencia',
    ghostDescription:
      'Si no se adaptan por espacio todos los elementos seleccionados a su torre de endoscopia, mostraremos a continuación los elementos adicionales (solo a modo de visualización).',
    esgDescription:
      'En el ESG-300 se incluye el carro de transporte TC-E300 (y la unidad de plasma de argón es opcional).',
    supportHintMedicalSpeciality: 'solo {supported}'
  },
  changeArticle: {
    subline: 'Su configuración personalizada',
    headline: 'Cambiar productos',
    showDetails: 'Mostrar detalles',
    scopesSelectedCounted: '{count} endoscopio seleccionado|{count} endoscopios seleccionados',
    currentlySelected: 'Seleccionado actualmente',
    productCode: 'Código de producto',
    hasRequirementsInfo: 'Seleccione primero uno de estos productos:',
    isRequirementInfo: 'Descarte primero estos productos seleccionados:',
    technicalSpecifications: 'Especificaciones técnicas',
    noArticlesAvailable:
      'No hay artículos compatibles con el procesador de vídeo seleccionado actualmente.',
    noScopesAvailable:
      'No hay endoscopios de esta selección compatibles con el procesador de vídeo actualmente seleccionado.',
    scopeGuideSubline: 'Sistema de navegación de imágenes en 3D',
    scopeGuideIntro: 'Funcionamiento de nuestro sistema de navegación de imágenes en 3D ScopeGuide',
    scopeGuideSummary:
      'ScopeGuide muestra una imagen en 3D en tiempo real del posicionamiento del endoscopio dentro del colon.',
    scopeGuideDescription:
      'Las bobinas electromagnéticas incorporadas a lo largo del tubo de inserción del endoscopio generan un campo magnético pulsado de baja intensidad que es captado por la antena receptora. <br /> Los pulsos magnéticos se utilizan para calcular la posición y orientación precisas del tubo de inserción para generar una imagen tridimensional en el monitor.',
    scopeGuideIncludedItems: 'Incluye los elementos siguientes:',
    notVisualized: '*No se visualiza en la configuración del carro de transporte.'
  },
  scopeFilter: {
    area: {
      label: 'Área',
      items: {
        [GIProcedureArea.UPPER_GI]: 'Aparato digestivo superior',
        [GIProcedureArea.LOWER_GI]: 'Aparato digestivo inferior',
        [GIProcedureArea.ERCP]: 'CPRE',
        [GIProcedureArea.ENTEROSCOPY]: 'Enteroscopia',
        [GIProcedureArea.ULTRASOUND]: 'Ultrasonidos'
      }
    },
    length: {
      label: 'Longitud del endoscopio',
      items: {
        [LowerGILength.INTERMEDIATE]: 'Intermedio',
        [LowerGILength.LONG]: 'Largo'
      }
    },
    respiratoryType: {
      label: 'Tipo de endoscopio',
      items: {
        [RespiratoryType.BRONCHOSCOPE]: 'Broncoscopia',
        [RespiratoryType.MINIPROBE]: 'Minisonda',
        [RespiratoryType.PLEURASCOPE]: 'Toracoscopio',
        [RespiratoryType.ULTRASOUND]: 'Ultrasonido'
      }
    },
    features: {
      label: 'Funciones',
      items: {
        [Feature.WATER_JET]: 'Inyección de agua',
        [Feature.HQ]: 'Compatible con alta frecuencia',
        [Feature.TRANSNASAL]: 'Transnasal',
        [Feature.PCF]: 'PCF'
      }
    },
    series: {
      label: 'Serie del endoscopio'
    }
  },
  accessories: {
    title: 'Accesorios',
    headline:
      'Para un sistema de endoscopia equipado completamente, recomendamos los accesorios siguientes'
  },
  request: {
    title: 'Enviar solicitud',
    subline: 'Su selección',
    headline:
      'Envíe su solicitud y nos pondremos en contacto con usted para facilitarle una oferta personalizada',
    areaTrolley: 'Torre de endoscopia',
    areaAccessories: 'Accesorios'
  },
  success: {
    title: 'Solicitud enviada',
    headline: 'Solicitud enviada',
    subline:
      'Su solicitud para la configuración de la torre de endoscopia se ha enviado correctamente. <br /> Nos pondremos en contacto con usted pronto.',
    secondSubline: 'Más información sobre EVIS X1',
    buttonNew: 'Iniciar una nueva configuración',
    buttonDetail: 'Más información',
    detailAssetPath: '/assets/successScreen_bottom_es.png',
    detailLink:
      'https://www.olympus.es/medical/es/Productos-y-soluciones/Productos/Product/EVIS-X1.html'
  },
  contact: {
    subline: 'Contacto',
    headline: 'Obtener ayuda',
    info: '¿Necesita ayuda para configurar su torre de endoscopia? Escríbanos y nos pondremos en contacto con usted en breve.',
    success:
      'Su solicitud para la configuración de la torre de endoscopia se ha enviado correctamente.<br />Pronto nos pondremos en contacto con usted.',
    customerQuestionHeadline: 'Solicite',
    backToConfiguration: 'Volver a la configuración'
  },
  saveConfiguration: {
    title: 'Continuar más tarde',
    subtitle: 'Guardar para más tarde',
    subline:
      'Introduzca su dirección de correo electrónico y le enviaremos un enlace para reanudar su configuración.',
    sendLink: 'Enviar el enlace',
    copyLinkToClipboard: 'Copiar este enlace al portapapeles',
    emailHeadline: '¿Cuál es su correo electrónico?',
    writeClipboardSuccess: 'Copiado en el portapapeles',
    writeClipboardFailed: 'Error al copiar al portapapeles',
    successHeadline: 'Configuración guardada',
    successSubline:
      'Su configuración se ha guardado correctamente.<br /> Continúe en cualquier momento siguiendo el siguiente enlace o desde el correo electrónico que le enviaremos en breve.'
  },
  footerUrlsLabel: {
    imprint: 'Edición',
    termsOfUse: 'Condiciones de uso',
    privacyNotice: 'Declaración de Privacidad',
    cookies: 'Cookies',
    cookiesSettings: 'Configuración de cookies',
    socialMedia: 'Redes Sociales'
  },
  contactForm: {
    country: 'País',
    countryHeadline: 'Seleccione su país',
    customerName: 'Nombre y apellidos',
    customerNameHeadline: '¿Cuál es su nombre?',
    email: 'Dirección de correo electrónico',
    emailHeadline: '¿Cuál es su correo electrónico?',
    customerPhoneNumber: 'Número de teléfono',
    customerPhoneNumberHeadline: '¿Cuál es su número de teléfono?',
    customerCompany: 'Su hospital/organización',
    customerCompanyHeadline: '¿Para qué hospital/organización trabaja?',
    customerQuestion: 'Su mensaje',
    customerQuestionHeadline: '¿Quiere contarnos algo más?',
    privacy:
      'Olympus respecta su privacidad.  Consulte nuestra {link} para saber cómo tratamos sus datos personales a la hora de responder a su solicitud.',
    checkboxRequired: '*Campos obligatorios'
  },
  actions: {
    back: 'Volver',
    confirm: 'Confirmar',
    continue: 'Continuar',
    no: 'No',
    save: 'Guardar',
    select: 'Seleccionar',
    selectNothing: 'Seleccionar nada',
    selected: 'Seleccionado',
    selectedNothing: 'Nada seleccionado',
    sendRequest: 'Enviar solicitud',
    submit: 'Enviar',
    yes: 'Sí'
  },
  error: {
    alert:
      'Desafortunadamente se ha producido un error. Espere unos segundos y vuelve a intentar su última acción o actualice esta página.',
    title: 'Algo ha ido mal',
    subline: 'Muchas gracias por interesarse en los dispositivos de Olympus.',
    info: 'Desafortunadamente se ha producido un error. Espere unos segundos y vuelve a intentar su última acción o actualice esta página.',
    contact: 'Póngase en contacto con nuestra {0}.',
    customerService: 'atención al cliente',
    refresh: 'Actualice esta página',
    retry: 'Reintentar'
  },
  formData: {
    customerName: 'Nombre',
    customerCompany: '@:contactForm.customerCompany',
    country: 'Pais',
    email: 'Correo electrónico',
    customerPhoneNumber: 'Teléfono',
    customerQuestion: 'Mensaje',
    optional: 'opcional'
  },
  countryOptions: [
    { label: 'Albania', value: 'AL' },
    { label: 'Argelia', value: 'DZ' },
    { label: 'Angola', value: 'AO' },
    { label: 'Armenia', value: 'AM' },
    { label: 'Austria', value: 'AT' },
    { label: 'Azerbaiyán', value: 'AZ' },
    { label: 'Bahrein', value: 'BH' },
    { label: 'Belarús', value: 'BY' },
    { label: 'Bélgica', value: 'BE' },
    { label: 'Bosnia y Herzegovina', value: 'BA' },
    { label: 'Botswana', value: 'BW' },
    { label: 'Bulgaria', value: 'BG' },
    { label: 'Camerún', value: 'CM' },
    { label: 'Croacia', value: 'HR' },
    { label: 'Chipre', value: 'CY' },
    { label: 'República Checa', value: 'CZ' },
    { label: 'Dinamarca', value: 'DK' },
    { label: 'Djibouti', value: 'DJ' },
    { label: 'Egipto', value: 'EG' },
    { label: 'Estonia', value: 'EE' },
    { label: 'Etiopía', value: 'ET' },
    { label: 'Finlandia', value: 'FI' },
    { label: 'Francia', value: 'FR' },
    { label: 'Georgia', value: 'GE' },
    { label: 'Alemania', value: 'DE' },
    { label: 'Ghana', value: 'GH' },
    { label: 'Grecia', value: 'GR' },
    { label: 'Hungría', value: 'HU' },
    { label: 'Islandia', value: 'IS' },
    { label: 'Irán', value: 'IR' },
    { label: 'Irak', value: 'IQ' },
    { label: 'Israel', value: 'IL' },
    { label: 'Italia', value: 'IT' },
    { label: 'Costa de Marfil', value: 'CI' },
    { label: 'Jordán', value: 'JO' },
    { label: 'Kazakhstan', value: 'KZ' },
    { label: 'Kenia', value: 'KE' },
    { label: 'Kuwait', value: 'KW' },
    { label: 'Kirguistán', value: 'KG' },
    { label: 'Letonia', value: 'LV' },
    { label: 'Líbano', value: 'LB' },
    { label: 'Lesoto', value: 'LS' },
    { label: 'Libia', value: 'LY' },
    { label: 'Lituania', value: 'LT' },
    { label: 'Macedonia', value: 'MK' },
    { label: 'Malawi', value: 'MW' },
    { label: 'Mauritania', value: 'MR' },
    { label: 'Mauricio', value: 'MU' },
    { label: 'Montenegro', value: 'ME' },
    { label: 'Marruecos', value: 'MA' },
    { label: 'Mozambique', value: 'MZ' },
    { label: 'Namibia', value: 'NA' },
    { label: 'Países Bajos', value: 'NL' },
    { label: 'Nigeria', value: 'NG' },
    { label: 'Noruega', value: 'NO' },
    { label: 'Omán', value: 'OM' },
    { label: 'Palestina', value: 'PS' },
    { label: 'Polonia', value: 'PL' },
    { label: 'Portugal', value: 'PT' },
    { label: 'Katar', value: 'QA' },
    { label: 'República del Congo', value: 'CG' },
    { label: 'Rumania', value: 'RO' },
    { label: 'Rusia', value: 'RU' },
    { label: 'Ruanda', value: 'RW' },
    { label: 'Arabia Saudita', value: 'SA' },
    { label: 'Senegal', value: 'SN' },
    { label: 'Serbia & Montenegro', value: 'RS' },
    { label: 'Seychelles', value: 'SC' },
    { label: 'Eslovaquia', value: 'SK' },
    { label: 'Eslovenia', value: 'SI' },
    { label: 'Somalia', value: 'SO' },
    { label: 'Republica de Sudafrica', value: 'ZA' },
    { label: 'Sudán del Sur', value: 'SS' },
    { label: 'España', value: 'ES' },
    { label: 'Sudán', value: 'SD' },
    { label: 'Swazilandia', value: 'SZ' },
    { label: 'Suecia', value: 'SE' },
    { label: 'Suiza', value: 'CH' },
    { label: 'Siria', value: 'SY' },
    { label: 'Tanzania', value: 'TZ' },
    { label: 'Togo', value: 'TG' },
    { label: 'Túnez', value: 'TN' },
    { label: 'Turquía', value: 'TC' },
    { label: 'Reino Unido e Irlanda', value: 'GB' },
    { label: 'Uganda', value: 'UG' },
    { label: 'Ucrania', value: 'UA' },
    { label: 'Emiratos Árabes Unidos', value: 'AE' },
    { label: 'Uzbekistán', value: 'UZ' },
    { label: 'Zambia', value: 'ZM' },
    { label: 'Zimbabue', value: 'ZW' }
  ]
};
